// Returns true if the given time is after current time
export function timeIsAfterNow(time: string | undefined | null): boolean {
  if (time === undefined || time === null) {
    return false;
  }

  const date = Date.parse(time);
  const now = new Date();
  // FIXME: this can cause hydration mismatch, if the client is for example in a different timezone than the server
  // and display info in template based on this function
  return date > now.getTime();
}
