import type { GPLocaleObject } from '~/locales/types';
import { USER_CURRENCY_STORE_KEY } from '~/store/currency';

// Injects an onMounted hook which updates the currency in the vue store after
// loading the pages on client side.
// The currency can be overridden by the locale store or the browser for special
// cases like en-GB.
export default (
  onloadCurrencySetHandler: () => void = () => undefined,
  userCurrencySetHandler: () => void = () => undefined,
) => {
  const { locale, localeProperties } = useI18n();
  const { supportedCurrencies } = useAppConfig();
  const authStore = useAuthStore();
  const currencyStore = useCurrencyStore();

  watch(
    () => currencyStore.currency,
    () => {
      if (currencyStore.preferUserDecision) {
        userCurrencySetHandler();
      }
    },
  );

  onNuxtReady(async () => {
    if (process.client) {
      // We have a user override in the local store
      const userCurrency = localStorage.getItem(
        USER_CURRENCY_STORE_KEY,
      ) as string;
      if (userCurrency) {
        if (
          // The user currency is supported
          supportedCurrencies.includes(userCurrency) &&
          // The user currency is not the same as the default locale currency
          // For example, if the user preference us EUR and the default locale is de, so we do not need to change the currency
          (localeProperties.value as GPLocaleObject).currency !== userCurrency
        ) {
          // The reason for the second fetch() is a little tricky: The fetch() is running,
          // and the currency will change before the fetch() returned. So the fetch()
          // collides with the code below. To prevent this, we call fetch() again to make
          // sure we have the right currency.

          currencyStore.setCurrency(userCurrency, true);
          onloadCurrencySetHandler();
        }
        return;
      }
      // GB is a special case here, because it is mapped to "en", but uses a different
      // currency. For that, we check the Accept-Language header for en-GB.
      // Because we can NOT use cookies server side (caching), can NOT access localStorage
      // on server side (no browser access), and can not determine GB from the /en/ path
      // prefix, we have to do this 100% client side.
      // We check if ...
      //   - no customer with a valid wallet is active
      //   - the browser Accept-Language is set to GB (so we have a special case here)
      //   - the language is EN (we enter the /en/ cache file)
      //   - and the user did not change the language by themselves (user did not override
      //     that special case)
      if (
        // XXX: Add more special cases for Malta and Ireland later on
        !authStore.isAuthenticated &&
        navigator.language.startsWith('en-GB') &&
        locale.value === 'en' &&
        !currencyStore.preferUserDecision
      ) {
        currencyStore.setCurrency('GBP', false);
        await onloadCurrencySetHandler();
      }
    }
  });
};
